import React  from "react";
import styled from "styled-components";
import {  BsInfoCircle } from 'react-icons/bs'
import PropTypes from 'prop-types'
import Creatable from 'react-select/creatable'

const SelectInput = styled(Creatable)`
    width: 100%;
`

export const InputFieldReq = ({ value, label, name, placeholder, type, bold, labelColor,
                                onChange, className, id, list, tooltip, toolTipText}) => {
    
    return (
        <div className="form__item">
            {label && <div className="form__label">
                        <label style={{ fontWeight: 600, ...(labelColor && { color: labelColor }) }} >{`${label}*`}</label>
                        { tooltip ? <div className="form__tooltip-icon" title={`${toolTipText}`}>
                            <BsInfoCircle size={20} color='blue' />
                        </div> : null }      
                </div>}
            <input
            type={type ? type : 'text'}
            value={value}
            name={name}
            className={className || "form__input" }
            id={id ? id : null}
            placeholder={placeholder}
            onChange={onChange}
            list={list || null}
            required
            />
        </div>
    )};

InputFieldReq.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string
}

export const TextAreaFieldReq = ({ className, rows, value, name, placeholder, onChange}) => {
    return (
        <textarea className={className} 
                  rows={rows} 
                  type="text" 
                  name={name}
                  placeholder={placeholder} 
                  value={value} 
                  onChange={onChange}
                  required
                >
        </textarea>
    )
}

TextAreaFieldReq.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string
}

export const TagFieldReq = ({ label, placeholder, id, handleOptionAdd, options, required }) => {
    return (
        <div className="form__item">
            {label && <label style={{ fontWeight: 600 }} >{`${label}${required ? '*': ''}`}</label>}
            <div className="form__service-block">
                <SelectInput options={options} 
                             onChange={opt => handleOptionAdd(null,id,opt.value)} 
                             placeholder={'Select from dropdown or type in custom option'}/>
            </div>
        </div>
    )};

TagFieldReq.propTypes = {
    handleOptionAdd: PropTypes.func.isRequired,
    placeholder: PropTypes.string
}