import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'

import {FiTwitter} from 'react-icons/fi'
import {FiInstagram} from 'react-icons/fi'
import {FiFacebook} from 'react-icons/fi'

const Wrapper = styled.div`
    background: #1C2238;
    padding-bottom: 10px;
`

const Container = styled.div`
    background-color: black;
    margin: 20px auto 0;
    padding: 5px 0;
`

const LogosContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
    width: 90%;
    margin: auto;
    @media (min-width: 450px) {
        display: flex;
        justify-content: center;
    }
`

const Image = styled.img`
    width: 100%;
    max-width: 100px;
    height: 30px;
    object-fit: cover;
`

const Footer = () => {
    const history = useHistory()

    const navigate = (url) => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        history.push(url)
    }

  return (
    <Wrapper>
    <section className="footer">
        <div className="about div">
            <h2 className='footer__h2'>Tap A Service</h2>
            <p className='footer__p_it' onClick={() => navigate('/about-us')}>About us</p>
            <p className='footer__p_it' onClick={() => navigate('/subscriptions')}>Subscriptions</p>
            <p className='footer__p_it' onClick={() => navigate('/terms-of-use')}>Terms and Conditions</p>
            <p className='footer__p_it' onClick={() => navigate('/privacy-policy')}>Privacy Policy</p>
            <p className='footer__p_it' onClick={() => navigate('/cookie-policy')}>Cookie Policy</p>
       </div>
       <div className="socials div">
            <p className='footer__p_it' onClick={() => history.push('/services')}>Services</p>
            <p className='footer__p_i'>Online Vehicle Maintenance</p>
            <p className='footer__p_i'>Roadside Assistance</p>
        </div>
        <div className="socials div">
            <p className='footer__p_it'>Address</p>
            <p className='footer__p_i'>11 Marco Polo St</p>
            <p className='footer__p_i'>Highveld</p>
            <p className='footer__p_i'>Centurion</p>
            <p className='footer__p_i'>0157</p>
        </div>
        <div className="socials div">
            <p className='footer__p_it'>Contact Us</p>
            <p className='footer__p_i'>info@tapaservice.com</p>
            <p className='footer__p_i'>+27634790289</p>
        </div>
       {/* <div className='language div'>
           <p className='footer__p'><FaLanguage color='white' /><span className='footer__p'>English</span></p>
            <p className='footer__p'><GoLocation color='white'/><span className='footer__p'>Johannesburg</span></p>
       </div> */}
        <div className="socials div">
            <p className='footer__p_it'>Follow us</p>
            <div className="socials__icons">
                <a rel='noreferrer' target='_blank'  href="https://www.facebook.com/profile.php?id=61564286013293"><FiFacebook color='white' cursor='pointer' /></a>
                <FiInstagram color='white'/>
                <FiTwitter color='white'/>
            </div>
        </div>
    </section>
    <Container>
    <LogosContainer>
        <Image src='./viza.png' />
        <Image src='./mastercard.jpeg' />
        <Image src='./paystack.png' />
        <Image src='./ozow.png' />
        </LogosContainer>
    </Container>
    <div className="copy-right div">
        <p className='footer__p'>&copy;{new Date().getFullYear()} Tap A Service</p>
    </div>
    </Wrapper>
  )
}

export default Footer
